/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: false // Set to false to use static SVG
};

const greeting = {
  username: "Omari Teck",
  title: "Salutations, I am Omar",
  subTitle: emoji(
    "A passionate Software Engineer 🚀 I have experiences in building all kinds of software solutions in NodeJS, Java, Python, and other programming languages."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1W39ejx9drceqZOjPi1A2kMfwgpDm10ED/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Ramo-11",
  linkedin: "https://linkedin.com/in/omar-abdelalim11",
  gmail: "omariteck@gmail.com",
  medium: "https://medium.com/@omariteck",
  twitter: "https://twitter.com/omariteck",
  // Instagram and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Areas of Expertise",
  subTitle: "Web development, Desktop Applications, Internet Programming, and more!",
  skills: [
    emoji("⚡ Create RESTful APIs to allow for server-client communication"),
    emoji("⚡ Integrate OpenAI API to create even more powerful software tools"),
    emoji("⚡ Develop secure, efficient, and user-friendly full stack applications"),
    emoji("⚡ Use OOP, design patterns, and SOLID principles to create robust software")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "databases",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Purdue University - Indianapolis",
      logo: require("./assets/images/iupuiLogo.jpeg"),
      subHeader: "Master of Science in Computer Science",
      duration: "January 2023 - May 2024",
    },
    {
      schoolName: "Purdue University - Indianapolis",
      logo: require("./assets/images/iupuiLogo.jpeg"),
      subHeader: "Bachelor of Science in Computer Engineering",
      duration: "August 2020  - May 2022",
    },
    {
      schoolName: "Ivy Tech Community College",
      logo: require("./assets/images/ivyTechLogo.png"),
      subHeader: "Associate of Science in Computer Engineering",
      duration: "August 2018  - May 2020",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend",
      progressPercentage: "100%"
    },
    {
      Stack: "API Integration", //Insert stack or technology you have experience in
      progressPercentage: "100%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Databases",
      progressPercentage: "80%"
    },
    {
      Stack: "Full stack development", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Stryker",
      companylogo: require("./assets/images/strykerLogo.jpeg"),
      date: "June 2022 – Present",
      descBullets: [
        "Integrate third-party medical devices with core service to establish seamless communication and data exchange for displaying alerts and alarms.",
        "Conduct thorough testing and debugging of integrated systems to ensure the accurate and reliable delivery of alerts and alarms from third-party medical devices, contributing to the overall safety and effectiveness of patient care."
      ]
    },
    {
      role: "Software Test Engineer",
      company: "Vocera - Now part of Stryker",
      companylogo: require("./assets/images/voceraLogo.png"),
      date: "January 2022 – June 2022",
      desc: "Conduct comprehensive testing and debugging to verify the accuracy, reliability, and seamless delivery of messages from one system to another, ensuring the utmost precision and effectiveness of patient data for caregivers."
    },
    {
      role: "Software Quality Assurance Intern",
      company: "Vocera - Now part of Stryker",
      companylogo: require("./assets/images/voceraLogo.png"),
      date: "June 2021 – August 2021",
      desc: "Ensure the systematic testing of software through CI (nightly run), and leveraging Gherkin for automating test cases, thus enhancing the overall quality and efficiency of the software development process."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  // projects: [
    // {
    //   image: require("./assets/images/nextuLogo.webp"),
    //   projectName: "Nextu",
    //   projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //   footerLink: [
    //     {
    //       name: "Visit Website",
    //       url: "http://nextu.se/"
    //     }
    //   ]
    // }
  // ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "Some of my writings. These include tutorials, discussions, thoughts, opinions, and others.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://medium.com/@omariteck/how-i-built-a-jira-client-application-that-tracks-program-increments-and-sprints-details-using-2a6b883f2296",
      title: "How I built a Jira Client Application using Spring Boot",
      description:
        "Deep dive on the architecture of my Jira Client program. I discuss some challenges and my thoughts during the development process."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  // number: "+92-0000000000",
  email_address: "omariteck@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "omariteck", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
